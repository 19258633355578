<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Dashboard</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Dashboard 
                  <span id="txtDashboardNomePlataforma">Financeiro</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="max-width: 1920px"
        >
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <section class="p-4">
                <div class="row">
                  <div class="col-12">

                    <div v-if="$store.state.fastPlataforma.plataforma_principal_cliente" class="text-info">
                      Os dados englobam essa plataforma e todas as plataformas filhas.
                    </div>
                    <div v-else class="text-info">
                      Os dados englobam apenas essa plataforma. Para exibir os dados das plataformas filhas configure esse plataforma como a plataforma principal.
                    </div>

                  </div>
                </div>
                <div class="row">   
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              RECEITA TOTAL (MÊS ATUAL)
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-success">
                              R$ {{formataPreco(fastDashboardValores.total)}}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-dollar-sign fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              MATRÍCULAS (MÊS ATUAL)
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-success">
                              R$ {{formataPreco(fastDashboardValores.totalMatriculas)}}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-dollar-sign fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              CURSOS (MÊS ATUAL)
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-success">
                              R$ {{formataPreco(fastDashboardValores.totalInvoice)}}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-dollar-sign fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 p-4 d-none">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              DESCONTOS (ANO)
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-danger">
                              R$ {{formataPreco(fastDashboardValores.descontos)}}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-dollar-sign fa-2x text-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Previsão financeira dos cursos
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="area"
                          height="350"
                          :options="chartOptions2"
                          :series="series2"
                        />
                      </div>
                    </div>  
                  </div>               
                  <div class="col-sm-12 col-md-12 col-lg-6 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Receita do mês por plataforma 
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="bar"
                          height="350"
                          :options="chartOptions4"
                          :series="series4"
                        />
                      </div>
                    </div>                    
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Receita por categoria 
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="pie"
                          width="380"
                          :options="chartOptions3"
                          :series="series3"
                        />
                      </div>
                    </div>                       
                  </div>  
                  <div class="col-12 p-4">
                    <div class="card shadow h-100">     
                      <div class="card-header">
                        Últimos movimentos
                      </div>               
                      <div class="card-body">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                Invoice
                              </th>
                              <th scope="col">
                                Data pagamento
                              </th>
                              <th scope="col">
                                Data vencimento
                              </th>
                              <th scope="col">
                                Plataforma
                              </th>
                              <th scope="col">
                                Parcela
                              </th>
                              <th scope="col">
                                Valor
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(e, index) in fastInvoice.total" :key="index">
                              <th scope="row">
                                #{{e.id_invoice}}
                              </th>
                              <td>
                                <span v-if="e.data_pagamento">
                                  {{formataDataT(e.data_pagamento)}}
                                </span>           
                                <span v-else class="text-danger">Pendente</span>                     
                              </td>
                              <td>{{formataDataT(e.data_vencimento)}}</td>
                              <td>{{e.nome_plataforma}}</td>
                              <td>
                                {{e.parcela}}/{{e.nr_parcelas}}
                              </td>
                              <td>R$ {{formataPreco(e.valor_parcela_total)}}</td>
                            </tr>
                                
                          </tbody>
                        </table>
                      </div>
                    </div>                    
                  </div>               
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos

export default {
  name: "HomeInternoTesourariaDashboard",
  components: {
    VueApexCharts
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Chart 2
      series2: [{
        name: "Receita prevista",
        data: []
      }],
      chartOptions2: {
      },
      series3: [],
      chartOptions3: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Matrículas', 'Cursos'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series4: [{
        name: 'Receita por plataforma',
        data: []
      }],
      chartOptions4: {
        /*
        annotations: {
          points: [{
            x: 'Novembro',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: 'Maior número',
            }
          }]
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'  
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Servings',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
        */
      },
      // Valores totais
      fastDashboardValores: {
        matriculas: [],
        parcelas: [],
        total: 0,
        totalMatriculas: 0,
        totalInvoice: 0,
        descontos: 0,        
        ano: new Date().getFullYear(),
        mes: (new Date().getMonth() + 1).length > 1 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1).toString(),
        plataformas:[]
      },
      // Boletos do mês
      fastInvoice: {
        total: [],
        pagos: [],
        pendentes: []
      },
      // Valores anuais
      fastDashboardValoresAnuais: [
        {
          mes: 1,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 2,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 3,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 4,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 5,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 6,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 7,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 8,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 9,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 10,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 11,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
        {
          mes: 12,
          total: 0,
          pago: 0,
          pendente: 0,
          vencido: 0
        },
      ]
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (
            this.$store.state.fastPlataformaModulos.modulo_secretaria
          ) {
            
            this.getMatriculaValores(this.$route.params.id_plataforma, this.fastDashboardValores.ano, this.fastDashboardValores.mes)
            .then(() => {
              // int id_plataforma, string ano_vencimento, string mes_vencimento, string ano_registro, string mes_registro   
              this.getInvoiceValores(this.$route.params.id_plataforma, '', '', this.fastDashboardValores.ano, this.fastDashboardValores.mes)
            })

            this.getInvoiceBoletos(this.$route.params.id_plataforma, this.fastDashboardValores.ano, this.fastDashboardValores.mes, '' , '')

            this.getInvoiceValoresAnual(this.$route.params.id_plataforma, this.fastDashboardValores.ano, '')
            
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getMatriculaValores(id_plataforma, ano_matricula, mes_matricula){
      return new Promise(async (resolve, reject) => {
        this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_valores", `id_plataforma=${id_plataforma}&ano_matricula=${ano_matricula}&mes_matricula=${mes_matricula}`)
        .then((res) => {
          if (res.length) {
            res.forEach(e => {
              this.fastDashboardValores.total += e.valor_matricula_total - e.desconto_matricula_total
              this.fastDashboardValores.totalMatriculas += e.valor_matricula_total - e.desconto_matricula_total
              this.fastDashboardValores.descontos += e.desconto_matricula_total

              this.fastDashboardValores.plataformas.push({
                id_plataforma: e.id_plataforma,
                total: e.valor_matricula_total - e.desconto_matricula_total
              })
            })
            this.fastDashboardValores.matriculas = res
            this.series3.push(this.fastDashboardValores.totalMatriculas)
          } 

          resolve(true)
        })
        .catch((e) => {
          console.log(e);
          reject(e)
        });
      });
    },
    async getInvoiceValores(id_plataforma, ano_vencimento, mes_vencimento, ano_registro, mes_registro){
      this.promiseGetFastApi("api/fast_tesouraria_invoice/lista_valores", `id_plataforma=${id_plataforma}&ano_vencimento=${ano_vencimento}&mes_vencimento=${mes_vencimento}&ano_registro=${ano_registro}&mes_registro=${mes_registro}`)
        .then((res) => {
          this.chartOptions4 = {            
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'  
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },
            
            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Servings',
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              },
            }
          }

          if (res.length) {
            res.forEach(e => {
              this.fastDashboardValores.total += e.valor_parcela_total - e.descontos
              this.fastDashboardValores.totalInvoice += e.valor_parcela_total - e.descontos
              this.fastDashboardValores.descontos += e.descontos

              this.chartOptions4.xaxis.categories.push(e.nome_plataforma)

              this.fastDashboardValores.plataformas.forEach(p => {
                if (p.id_plataforma == e.id_plataforma) p.total += e.valor_parcela_total - e.descontos
              })

              
            })
            this.fastDashboardValores.parcelas = res
            this.series3.push(this.fastDashboardValores.totalInvoice)
          } 
          
          this.fastDashboardValores.plataformas.forEach(p => {
            this.series4[0].data.push(p.total)
          })
         
          
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getInvoiceBoletos(id_plataforma, ano_vencimento, mes_vencimento, ano_registro, mes_registro){
      this.promiseGetFastApi("api/fast_tesouraria_invoice/lista_boletos", `id_plataforma=${id_plataforma}&ano_vencimento=${ano_vencimento}&mes_vencimento=${mes_vencimento}&ano_registro=${ano_registro}&mes_registro=${mes_registro}`)
        .then((res) => {          
          console.log("getInvoiceBoletos", res)
          if (res.length) {
            this.fastInvoice.total = res

            res.forEach(e => {
              if (e.pago) this.fastInvoice.pagos.push(e)
              else
                this.fastInvoice.pendentes.push(e)
            })
            
          } 
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getInvoiceValoresAnual(id_plataforma, ano_vencimento, ano_registro){
      this.promiseGetFastApi("api/fast_tesouraria_invoice/lista_valores_anual", `id_plataforma=${id_plataforma}&ano_vencimento=${ano_vencimento}&ano_registro=${ano_registro}`)
        .then((res) => {          
          if (res.length) {
            res.forEach(e => {
              this.fastDashboardValoresAnuais[parseInt(e.mes_vencimento) - 1].total += e.valor_parcela_total - e.descontos
            })

            this.chartOptions2 = {
              chart: {
                type: 'area',
                height: 350,
                zoom: {
                  enabled: false
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight'
              },
              
              title: {
                text: 'Ano atual',
                align: 'left'
              },
              subtitle: {
                text: '',
                align: 'left'
              },
              labels: ['01 Jan', '01 Feb', '01 Mar', '01 Apr', '01 May', '01 Jun', '01 Jul', '01 Aug', '01 Sep', '01 Oct', '01 Nov', '01 Dec'],
              xaxis: {
                type: 'datetime',
              },
              yaxis: {
                opposite: true
              },
              legend: {
                horizontalAlign: 'left'
              }
            }
            this.fastDashboardValoresAnuais.forEach(e => {
              this.series2[0].data.push(e.total)
            })
            
          } 
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
};
</script>

<style></style>
